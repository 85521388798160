import React, { useState, useEffect } from 'react'
import { navigate, Link } from 'gatsby'
import { useWindowScroll, useHover, useLocation } from 'react-use';
import styled, { css } from 'styled-components'
import Marquee from "react-marquee-slider";
import moment from 'moment'
import timezone from 'moment-timezone'
import { motion } from 'framer-motion'

import { Drawer, AnimatedLogo } from '../'

import { media, useBreakpoint, isClient } from '../../styles/utils'
import { container, bgIcon, padding, hoverState, colours, textHover } from '../../styles/global'
import { getGlobalData } from '../../utils'

const TRIGGER_Y = 100;

const Header = (props) => {
	const { background, floating, breadcrumb, breadcrumbLink, isHome, primaryColor } = props
	const { data } = getGlobalData('marquee')
	const { y } = useWindowScroll();
	const [menuActive, setMenuActive] = useState(false)
	const [scrolled, setScrolled] = useState(false)
	const isTablet = useBreakpoint('tablet')
	const location = useLocation()

	useEffect(() => {
		if (y > TRIGGER_Y && !scrolled) setScrolled(true)
		if (y < TRIGGER_Y && scrolled) setScrolled(false)
	}, [y])

	const renderMenuItems = () => {
		const menuItems = [
			{
				name: 'The Value of Design',
				link: '/the-value-of-design'
			},
			{
				name: 'Client Services',
				link: '/client-services'
			},
			{
				name: 'Office Expertise',
				link: '/office-expertise'
			},
			{
				name: 'Resources',
				link: '/resources'
			},
			{
				name: 'Careers',
				link: '/careers'
			},
			{
				name: 'Contact, Social',
				link: '/contact-social'
			},
		]	
		
		const items = menuItems.map((item, i) => {
			return (
				<Link
					to={item.link}	
					key={i}
				>
					<Item 
						as={motion.div}
						animate={menuActive ? 'active' : 'hidden'}
						{...animatedMenuItem(i)}
					>
						{item.name}
					</Item>
				</Link>
			)
		})

		return (
			<MenuItems>
				{items}
			</MenuItems>
		)
	}

    const renderMenu = () => {        
        return (
            <Drawer
                position={'left'}
                active={menuActive}
				outsideClick={() => menuActive && setMenuActive(false)}
				showCover
            >
                <MenuToggle
					onClick={() => setMenuActive(!menuActive)}
					inMenu
					isActive={menuActive}
				>
					Close
				</MenuToggle>

				{renderMenuItems()}
            </Drawer>
        )        
    }

	const renderMenuToggle = (params) => {
		return (
			<MenuToggle
				onClick={() => setMenuActive(!menuActive)}
				isHome={isHome}
			>
				Menu
				{breadcrumb ? ', ' : ''}
			</MenuToggle>
		)
	}

	const renderBreadcrumb = () => {
		if (!breadcrumb) return;

		return (
			<Breadcrumb
				as={breadcrumbLink ? Link : 'div'}
				to={breadcrumbLink}
			>
				<Subheading
					as={!props.breadcrumbDisableH1 ? 'h1' : 'div'}
				>
					{breadcrumb}
				</Subheading>
			</Breadcrumb>
		)		
	}

	const renderLogo = () => {
		return (
			<Logo
				as={Link}
				to={'/'}
				breadcrumbVisible={breadcrumb}
			>
				<Hover>
					<Subheading>NHO<span className="primary-color">®</span></Subheading>
					<Subheading>Neil Hugh Office</Subheading>
				</Hover>
			</Logo>
		)
	}

	const renderTimezone = () => {
		const now = moment();

		const timezones = data?.marquee?.map((item) => {
			const lat = item.location_map.lat
			const lng = item.location_map.lng
			const timezone = moment.tz.guess([lat, lng])

			return {
				name: item.location_name,
				time: now.tz(timezone).format('MM.DD, hh.mmA')
			}
		})

		const items = timezones?.map((item, i) => {
		return (
				<Subheading key={i}>
					{`${item.name} ${item.time}`}
				</Subheading>
			)
		})

		return (
			<Timezone>
				<Marquee velocity={10}>{items}</Marquee>
			</Timezone>
		)
	}
	
	return (
		<>
			<Wrapper
				background={background}
				floating={scrolled || floating}
				primaryColor={primaryColor}
			>
				<Container>
					{renderMenuToggle()}
					{renderBreadcrumb()}
					{renderLogo()}
					{renderTimezone()}
				</Container>	

				{renderMenu()}
			</Wrapper>
		</>
	)
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Text = styled.div``
const Info = styled.div``
const Item = styled(motion.div)``

// Layout

const Wrapper = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 3;
	background: white;
	transition: all 0.45s ease;

	/* Background */

	${props => {
		if (props.background) return css`
			background: ${props.background};
		`
	}}

	/* Floating */

	${props => {
		if (props.floating) return css`
			box-shadow: 0 2px 10px 0 rgba(0,0,0,0.10);
		`
	}}

	/* Primary Color */

	${props => {
		if (props.primaryColor) return css`
			color: ${props.primaryColor};

			.primary-color {
				color: ${props.primaryColor};
			}
		`
	}}
`

const Container = styled.div`
	${container}
	${padding}
	height: 100px;
	display: flex;
	align-items: center;

	${media.phone`
		height: 55px;
		padding-top: 2px;
	`}
`

// Menu Toggle

const MenuToggle = styled.div`
	cursor: pointer;
	
	${props => {
		if (!props.isHome) return css`
			${textHover};
		`
	}}

	${props => {
		if (props.isActive) return css`
			color: black;
		`
	}}
`

// Breadcrumb

const Breadcrumb = styled.div`
	margin-left: 0.25em;

	span {
		color: ${colours.offWhite}
	}

	h1 {
		${media.phone`
            font-size: 18px;
            line-height: 1.2em;
        `}
	}
`

// Logo

const Hover = styled.div``

const Logo = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	

	display: flex;
	justify-content: center;
	height: 1.35em;
	overflow: hidden;
	text-align: center;

	${media.phone`
		width: 70px;
		height: 1.1em;
	`}
	
	${Hover} {
		position: relative;
		transition: opacity 0.35s ease;
		width: 204px;

		${Subheading} {
			transition: opacity 0.15s ease;		
			opacity: 1;
				
			&:nth-child(2) {
				opacity: 0;
				position: absolute;
				bottom: 1px;
			}

			span {
				font-size: 12px;
			}	
		}
	}

	&:hover {
		${Hover} {
			${Subheading} {
				opacity: 0;
				
				&:nth-child(2) {
					opacity: 1;
				}
			}
		}
	}

	${media.tablet`
		&:hover {
			${Hover} {
				${Subheading} {
					opacity: 1;
					
					&:nth-child(2) {
						opacity: 0;
					}
				}
			}
		}
	`}

	/* Mobile Positioning */

	${props => {
		if (props.breadcrumbVisible) return css`
			${media.tabletPortrait`
				transform: translateY(-50%);
				right: 20px;
				text-align: right;
				left: auto;
			`}
		`
	}}
`

// Timezone 

const Timezone = styled.div`
	margin-left: auto;
	max-width: 290px;

	${Subheading} {
		margin-right: 290px;
	}

	${media.tabletPortrait`
		display: none;
	`}
`

// Menu Items

const MenuItems = styled.div`
	margin-top: 90px;
	display: flex;
	flex-direction: column;
	color: black;

	${Item} {
		${textHover}

		${media.phone`
			line-height: 1.34em;
		`}
	}
`

const animatedMenuItem = (i) => {
	return {
		initial: 'hidden',
		variants: {
			active: {
				opacity: 1,
				transition: {
					delay: 0.05 + (i * 0.1),
					duration: 0.85, 
					ease: 'easeInOut',
				},
			},
			hidden: {
				opacity: 0,
				transition: {
					duration: 0.25, 
					ease: 'easeInOut',
				},
			},
		},
	}
}

export default Header
