import React, { useState } from 'react'
import { navigate, Link } from 'gatsby'
import Form, { useForm } from 'react-dynamic-form'
import styled, { css } from 'styled-components'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import Marquee from 'react-marquee-slider'

import { Field } from '../'

import { media } from '../../styles/utils'
import { validators } from '../../validators'
import {
    container,
    padding,
    type,
    textHover,
    colours,
} from '../../styles/global'
import { getGlobalData } from '../../utils'

const Footer = (props) => {
    const { data } = getGlobalData('footer')
    const [form, { fieldState, formSubmit, setError }] = useForm()
    const [formComplete, setFormComplete] = useState(false)
    const [canSubmit, setCanSubmit] = useState(false)
    const [creditsHovering, setCreditsHovering] = useState(false)    

    const handleSubmit = (fields, reset) => {
        let data = {
            email: fields['email'].value,
        }

        addToMailchimp(data.email).then((mc_resp) => {
            if (mc_resp.result == 'success') {
                setFormComplete(true)
                setError(false)
            }

            if (mc_resp.result == 'error') {
                setError(mc_resp.msg)
            }
        })

        reset()
    }

    const renderOffices = () => {
        if (!data.office_links) return

        const items = data.office_links.map((item, i) => {
            return (
                <Item key={i} as={Link} to={item.link}>
                    {item.title}
                </Item>
            )
        })

        return <Offices>{items}</Offices>
    }

    const onFormChange = (fieldAction) => {
        const { action, fieldState } = fieldAction
        const email = fieldState?.email?.value

        if (action == 'field-change') {
            setError(false)
            if (validators.email(email) && !canSubmit) setCanSubmit(true)
            if (!validators.email(email) && canSubmit) setCanSubmit(false)
        }
    }

    const renderSubscribe = () => {
        const fields = [
            {
                type: 'email',
                name: 'email',
                placeholder: 'Subscribe',
                validator: validators.email,
                errorMessage: 'Please enter a valid email',
            },
        ]

        return (
            <Subscribe>
                {!formComplete && (
                    <>
                        <Form
                            form={form}
                            data={fields}
                            styles={formStyles}
                            renderInput={<Field />}
                            renderSubmit={false}
                            onSubmit={handleSubmit}
                            moveToInvalidField={false}
                            onFormChange={onFormChange}
                        />

                        <Submit active={canSubmit} onClick={formSubmit}>
                            Submit
                        </Submit>

                        {form.error && <Error>{form.error}</Error>}
                    </>
                )}

                {formComplete && <Subheading>Subscribed</Subheading>}
            </Subscribe>
        )
    }

    const renderSocial = () => {
        if (!data.social_links) return

        const items = data.social_links.map((item, i) => {
            return (
                <Item key={i} as={Link} to={item.link} target={'_blank'}>
                    {item.title}
                </Item>
            )
        })

        return (
            <Social>
                {items}

                <Item>© NHO</Item>
            </Social>
        )
    }

    const renderPrivacyPolicy = () => {
        if (!data?.privacy_pdf) return

        return (
            <PrivacyPolicy hovering={creditsHovering}>
                <Subheading as={Link} target={'_blank'} href={data?.privacy_pdf}>
                    {'Privacy Policy'}
                </Subheading>
            </PrivacyPolicy>
        )
    }

    const renderCredits = () => {
        if (!data.credits) return

        return (
            <Credits
                hovering={creditsHovering}
                onMouseEnter={() => setCreditsHovering(true)}
            >
                <Subheading>Credits</Subheading>

                <Scrolling
                    onMouseLeave={() =>
                        creditsHovering && setCreditsHovering(false)
                    }
                >
                    <Marquee velocity={7}>
                        <Subheading
                            dangerouslySetInnerHTML={{ __html: data.credits }}
                        />
                        <Subheading
                            dangerouslySetInnerHTML={{ __html: data.credits }}
                        />
                    </Marquee>
                </Scrolling>
            </Credits>
        )
    }

    const renderBCorpLogo = () => {
        return (
            <BCorpLogo />
        )
    }

    return (
        <Wrapper>
            <Container>
                <Heading dangerouslySetInnerHTML={{ __html: data.tagline }} />

                {renderOffices()}
                {renderSubscribe()}
                {renderSocial()}
                {renderBCorpLogo()}
               
                <PrivacyAndCredits>
                    {renderPrivacyPolicy()}
                    {renderCredits()}
                </PrivacyAndCredits>
            </Container>
        </Wrapper>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Text = styled.div``
const Info = styled.div``
const Item = styled.div``

// Layout

const Wrapper = styled.div`
`

const Container = styled.div`
    ${container}
    ${padding}
	flex-direction: column;
    align-items: flex-start;
    height: 360px;
    padding-top: 40px;
    padding-bottom: 36px;
    position: relative;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: white;

    ${Heading} {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        ${media.phone`
			position: initial;
			transform: none;
			margin-bottom: 40px;
            text-align: left;
            padding-right: 40px;
		`}
    }

    ${media.phone`
		padding-top: 50px;
		padding-bottom: 16px;
		// height: 315px;
        height: 465px;
	`}
`

// Office

const Offices = styled.div`
    display: flex;
    flex-direction: column;

    ${Item} {
        ${textHover}
    }
`

// Subscribe

const Submit = styled.div`
    opacity: 0;
    pointer-events: none;
    transition: all 0.25s ease;

    ${(props) => {
        if (props.active)
            return css`
                opacity: 1;
                pointer-events: all;
                cursor: pointer;
            `
    }}
`

const Subscribe = styled.div``

const formStyles = css`
    width: 100%;

    .dynamic-fields {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        width: 100%;

        .dynamic-field {
            &,
            input {
                height: 1.5em !important;
            }
        }
    }
`

export const Error = styled.div`
    color: ${colours.red};
    margin-top: 16px;
`

// BCorp Logo

const BCorpLogo = styled.div`
    position: absolute;
    right: 20px;
    top: 30px;
    z-index: 2;
    width: 90px;
    height: 130px;
    background-image: url(${require('../../assets/images/bcorp-logo-vertical.svg')});
    background-repeat: no-repeat;

    ${media.tablet`
        width: 190px;
        height: 120px;
        right: 10px;
    `}

    ${media.phone`
        right: auto;
        top: auto;
        bottom: 60px;
        left: 15px;
    `}
`

// Credit

const Social = styled.div`
    display: flex;
    margin-top: auto;

    ${Item} {
        ${type.caption};

        span {
            font-size: 12px;
            color: black;
        }	

        &:not(:last-child) {
            margin-right: 18px;
            ${textHover};
        }
    }
`

// Privacy Policy

const PrivacyAndCredits = styled.div`
    position: absolute;
    right: 40px;
    bottom: 40px;
    cursor: default;
    z-index: 2;

    display: flex;
    align-items: baseline;

    ${media.phone`
        right: 16px;
        bottom: 16px;
    `}
`

const PrivacyPolicy = styled.div`
    ${Subheading} {
        transition: opacity 0.35s ease;
        margin-right: 18px;
        &,
        p,
        a {
            ${type.caption};
            display: block;
        }
    }

    ${(props) => {
        if (props.hovering)
            return css`
                > ${Subheading} {
                    opacity: 0;
                }

                ${Scrolling} {
                    opacity: 1;
                    transition: opacity 0.65s ease;
                    pointer-events: all;
                }
            `
    }}
`

// Credits

const Scrolling = styled.div`
    pointer-events: none;
`

const Credits = styled.div`

    ${media.phone`
		display: none;
	`}

    ${Subheading} {
        transition: opacity 0.35s ease;

        &,
        p,
        a {
            ${type.caption};
        }
    }

    ${Scrolling} {
        position: absolute;
        bottom: -12px;
        right: 0;

        width: 400px;
        opacity: 0;
        transition: opacity 0.25s ease;

        ${Subheading} {
            margin-left: 32px;
        }
    }

    ${(props) => {
        if (props.hovering)
            return css`
                > ${Subheading} {
                    opacity: 0;
                }

                ${Scrolling} {
                    opacity: 1;
                    transition: opacity 0.65s ease;
                    pointer-events: all;
                }
            `
    }}
`

export default Footer
