import React, { useState, useEffect } from 'react'
import { navigate, Link } from 'gatsby'
import Form, { useForm } from 'react-dynamic-form'
import styled, { css } from 'styled-components'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { useLocation, useMount, useHash } from 'react-use';
import OutsideClickHandler from 'react-outside-click-handler';

import { Field } from '..'

import { isClient, media } from '../../styles/utils'
import { validators } from '../../validators'
import { container, padding, type, textHover, colours } from '../../styles/global'
import { getGlobalData } from '../../utils'

const Newsletter = (props) => {
	const { data } = getGlobalData('subscribe_modal')
	const [active, setActive] = useState(false)	
	const [form, { fieldState, formSubmit, setError }] = useForm()
	const [formComplete, setFormComplete] = useState(false)
	const location = useLocation()
	const [hash, setHash] = isClient() && useHash()

	useMount(() => {
		if (isClient()) {
			window.showNewsletter = show;
		}

		if (isClient() && location?.pathname !== '/') return

		if (localStorage.announcementSeen !== 'true') {
			setTimeout(() => setActive(true), 5000)
		}
	})

	const checkInstagramTraffic = () => {
        const searchParams = new URLSearchParams(window.location.search)
        const isFromInstagram = searchParams.get('utm_source') === 'later-linkinbio'

        if (isFromInstagram && !active) {
            show()
        }
    }

	useEffect(() => {
		if (hash == '#showNewsletter' && !active) {
			show()
			setHash('newsletter')
		}
	}, [hash])

	useEffect(() => {
        if (isClient()) {
            checkInstagramTraffic()
        }
    }, [location])

	const hide = () => {
		localStorage.announcementSeen = true
		setActive(false)
	}

	const show = () => {
		setActive(true)
	}

	const handleSubmit = (fields, reset) => {

		let data = {
			email: fields['email'].value,
		}

		addToMailchimp(data.email).then(mc_resp => {
            if (mc_resp.result == 'success') {
				localStorage.announcementSeen = true
                setFormComplete(true)
                setError(false)
            } 

            if (mc_resp.result == 'error') {
                setError(mc_resp.msg)
            }
        })
		
		reset()
	}

	const renderSubscribe = () => {

		const fields = [
			{
				type: 'email',
				name: 'email',
				label: 'E-MAIL:',
				placeholder: ' ',
				validator: validators.email,
				errorMessage: 'Please enter a valid email'
			},
		]

		return (
			<Subscribe>
				{!formComplete && (
					<>
						<Form
							form={form}
							data={fields}
							styles={formStyles}
							renderInput={<Field />}
							renderSubmit={false}
							onSubmit={handleSubmit}
							moveToInvalidField={false}
						/>

						<Submit
							onClick={formSubmit}
						>
							Subscribe
						</Submit>

						{form.error && <Error>{form.error}</Error>}
					</>
				)}

				{formComplete && (
					<Subheading>
						Subscribed
					</Subheading>
				)}
			</Subscribe>
		)
	}

	return (
		<OutsideClickHandler
			onOutsideClick={hide}
		>
			<Wrapper
				active={active}
			>
				<Text
					dangerouslySetInnerHTML={{__html: data.description}}
				/>

				{renderSubscribe()}

				<Heading>
					Mail
				</Heading>

				<Subheading
					onClick={hide}
				>
					Close
				</Subheading>
			</Wrapper>
		</OutsideClickHandler>
	)
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Text = styled.div``
const Info = styled.div``
const Item = styled.div``

// Layout

const Wrapper = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;
	background: ${colours.offWhite};
	max-width: 510px;
	padding: 40px 65px 16px;

	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	${media.phone`
		max-width: calc(100vw - 48px);
		padding: 20px 20px 16px;
		width: 100%;
	`}

	${Text} {
		&, p {
			${type.bodySmall};	
			text-align: center;
		}
	}

	${Heading} {
		margin: 190px 0 180px;
		font-size: 80px;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		text-align: center;	
		line-height: 0;

		${media.phone`
			font-size: 60px;
			margin: 90px 0 80px;
		`}
	}

	${Subheading} {
		${type.caption};
		cursor: pointer;
	}

	/* Active */

	transition: opacity 0.5s ease;
	opacity: 0;
	will-change: opacity;
	pointer-events: none;
	
	${props => {
		if (props.active) return css`
			opacity: 1;
			pointer-events: all;
		`
	}}
`


// Subscribe	

const Submit = styled.div`
	${type.bodySmall};	
	margin-top: 42px;
	text-transform: uppercase;
	cursor: pointer;

	${media.phone`
		margin-top: 24px;
	`}
`

const Subscribe = styled.div`
	width: 100%;

	${Subheading} {
		text-transform: uppercase;
		margin-top: 24px;
	}
`

const formStyles = css`
    width: 100%;
	margin-top: 20px;

    .dynamic-fields {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        width: 100%;

        .dynamic-field {
			&, input {
				height: 1.5em !important;
				
				&, 
				&::placeholder,
				.field-label {
					${type.bodySmall}
				}

				.field-label {
					min-width: 70px;
					margin-right: 2px;
				}
			}

			input {
				border-bottom: 1px solid black;
			}
        }
    }
`

export const Error = styled.div`
    color: ${colours.red};
    margin-top: 16px;
`


export default Newsletter
